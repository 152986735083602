.container {
    display: inline-flex;
    align-items: center;

    img {
        margin-right: 6px;
    }

    .bold{
        font-weight: 600;
    }

    a {
        text-decoration: none;
    }
}