.container {
    background: #00000073;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}