.container {
    display: flex;
    flex-direction: column;
    width: 285px;
    gap: 15px;
    font-weight: 300;
    
    > * {
        margin-bottom: 12px;
    }

    h2 {
        margin: 0;
        font-weight: 500;
    }

    .profileName {
        opacity: 0.6;
    }

    .location{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .emails {
        display: flex;
        flex-direction: column;
    }

    .bio {
        white-space: pre-line;
    }
}