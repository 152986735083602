@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
.card_container__VYbdS{background-color:#12171f;padding:30px;border-radius:12px}.card_container__VYbdS .card_headerWrapper__2Rzza{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;margin-bottom:24px}.card_container__VYbdS .card_headerWrapper__2Rzza .card_header__2jpJW{color:#c9d1d9;font-weight:600}.card_container__VYbdS .card_headerWrapper__2Rzza .card_subheader__1pSyC{color:#e1e3e6;opacity:.65}.card_container__VYbdS .card_content__3fV0v{margin-top:30px;height:100%}
.BarGraph_bar__1Z805{fill:#355aa7}
.TextInput{font-size:14px}.TextInput__label{font-size:14px;font-weight:500}
.Text_label__FKWkp{font-size:14px;font-weight:100;opacity:.65}.Text_value__32x6y{font-size:24px;font-weight:bold}.Text_header__1dPU0{font-size:26px}.Text_loaderWrapper__3zCzb{margin:16px}
.FullscreenWrapper_container__3ibFg{background:#00000073;position:absolute;top:0px;left:0px;z-index:10;height:100%;width:100%;-webkit-align-items:center;align-items:center;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;overflow:hidden}
.ProfilePanel_container__3ubbs{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;width:285px;grid-gap:15px;gap:15px;font-weight:300}.ProfilePanel_container__3ubbs>*{margin-bottom:12px}.ProfilePanel_container__3ubbs h2{margin:0;font-weight:500}.ProfilePanel_container__3ubbs .ProfilePanel_profileName__EG3Qf{opacity:.6}.ProfilePanel_container__3ubbs .ProfilePanel_location__1n3Bk{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;grid-gap:5px;gap:5px}.ProfilePanel_container__3ubbs .ProfilePanel_emails__m6MHl{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.ProfilePanel_container__3ubbs .ProfilePanel_bio__3eXlL{white-space:pre-line}
.FollowPanel_container__2nxrn{padding:0}.FollowPanel_container__2nxrn li{display:inline;padding-right:12px}
.Text_container__39Ld6{display:-webkit-inline-flex;display:inline-flex;-webkit-align-items:center;align-items:center}.Text_container__39Ld6 img{margin-right:6px}.Text_container__39Ld6 .Text_bold__1Vp7F{font-weight:600}.Text_container__39Ld6 a{text-decoration:none}
.OverviewCard_container__1cXAy{display:grid;grid-template-columns:auto auto;grid-template-rows:auto 12px auto}.OverviewCard_container__1cXAy :first-child{grid-column-start:1;grid-row-start:1;justify-self:start}.OverviewCard_container__1cXAy :nth-child(2){grid-column-start:2;grid-row-start:1;justify-self:start}.OverviewCard_container__1cXAy :nth-child(3){grid-column-start:1;grid-row-start:3}.OverviewCard_container__1cXAy :nth-child(4){grid-column-start:2;grid-row-start:3}
body{font-family:"Poppins";font-size:16px;color:#ededed;background-color:#171e27;margin:0;padding:0;overflow-x:hidden}body a{color:#ededed;text-decoration:none;cursor:pointer}body ::-webkit-scrollbar{width:4px;height:4px}body ::-webkit-scrollbar-track{background-color:#ffffff33;border-radius:10px}body ::-webkit-scrollbar-thumb{background:#fff;border-radius:10px}body ::-webkit-scrollbar-thumb:hover{background:#bbb}
