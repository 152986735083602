.container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto 12px auto;

    :first-child {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: start;
    }

    :nth-child(2) {
        grid-column-start: 2;
        grid-row-start: 1;
        justify-self: start;
    }

    :nth-child(3) {
        grid-column-start: 1;
        grid-row-start: 3;
    }

    :nth-child(4) {
        grid-column-start: 2;
        grid-row-start: 3;
    }
}