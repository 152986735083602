@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');
@import "./constants.scss";

body {
    font-family: 'Poppins';
    font-size: 16px;
    color: $text-primary-color;
    background-color: #171E27;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
    a {
        color: $text-primary-color;
        text-decoration: none;
        cursor: pointer;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: #ffffff33;
        border-radius: 10px;
    }
   
    ::-webkit-scrollbar-thumb {
        background: #FFFFFF; 
        border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb:hover {
        background: #bbbbbb; 
    }
}