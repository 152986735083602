@import "../../constants.scss";

.container {
    background-color: $background-primary-color;
    padding: 30px;
    border-radius: 12px;

    .headerWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .header {
            color: $header-primary-color;
            font-weight: 600;
        }
    
        .subheader {
            color: $text-secondary-color;
            opacity: 0.65,
        }
    }

    .content {
        margin-top: 30px;
        height: 100%;
    }
}