$background-primary-color: #12171F;
$background-secondary-color: #171E27;

$header-primary-color: #C9D1D9;

$text-primary-color: #EDEDED;
$text-secondary-color: #E1E3E6;

$primary-color: #4D84FF;
$secondary-color: #355AA7;

$primary-border-color: #434B57;
$secondary-border-color: #303D52;